import axios, { AxiosInstance } from 'axios';

import { FreightCalculator } from '@ccb/utils/dist/models';

export class CCBFreight implements FreightCalculator {
    private ccb: AxiosInstance;
    constructor() {
        this.ccb = axios.create({
            baseURL: process.env.BASE_URL,
        });
    }

    async get(deliveryPostcode: string, weightKg: number) {
        return (
            (
                await this.ccb.get('/api/freight', {
                    params: { delivery_postcode: deliveryPostcode, weightKg },
                })
            ).data.freightCharge * 100
        );
    }
}
