import { defineComponent } from 'vue';
import { event } from 'vue-gtag';
import { models } from '@ccb/utils';

import { logger } from '@/utils';

import { CCBFreight } from '../models/freight';
import api from '../models/api';
import states from '../models/states';

declare let Razorpay: any;

export default defineComponent({
    props: { game: { type: models.SellableGame, required: true } },
    created() {
        event('add_to_cart', { currency: 'INR', value: this.game.price / 100 });
    },
    data() {
        return {
            order: new models.Order(this.game, new models.Address()),
            freightCalculator: new CCBFreight(),
            isDirectPickup: false,
            shipping: new models.Address(),
            paymentFailed: false,
            couponCode: '',
            coupon: null as models.Coupon | null,
            states: states,
        };
    },
    computed: {
        billing: function () {
            return this.order.billing;
        },

        pretaxPrice: function () {
            return this.order.legacyGamePrice() / 100;
        },
        pretaxUpiPaymentDiscount: function () {
            return this.pretax(models.ChargeType.UPI_DISCOUNT);
        },
        giftWrappingCharge: function () {
            return this.amount(models.ChargeType.GIFT_WRAPPING);
        },
        pretaxGiftWrappingCharge: function () {
            return this.pretax(models.ChargeType.GIFT_WRAPPING);
        },
        pretaxFreightCharge: function () {
            return this.pretax(models.ChargeType.FREIGHT);
        },
        pretaxFreightDiscountCharge: function () {
            return this.pretax(models.ChargeType.FREIGHT_DISCOUNT);
        },
        gst: function () {
            return this.order.gst() / 100;
        },
        total: function () {
            return this.order.total() / 100;
        },

        orderValid: function () {
            return this.order.valid();
        },
    },
    watch: {
        isDirectPickup(_newValue, _oldValue) {
            this.order.isDirectPickup = _newValue;
            this.updateFreightCharge();
        },
    },
    methods: {
        async updateCoupon() {
            if (!this.couponCode) {
                this.coupon = null;
                return;
            }

            try {
                this.coupon = models.Coupon.fromJSON(
                    (await api.get(`/coupons/${this.couponCode}`)).data
                );

                if (this.coupon.valid(this.order as models.Order)) {
                    this.order.addCoupon(this.coupon as models.Coupon);
                    this.couponCode = '';
                    this.coupon = null;
                } else {
                    logger.error(`Invalidated coupon ${this.couponCode}`);
                }
            } catch (e) {
                this.coupon = null;
            }
        },
        amount(charge: models.ChargeType) {
            return (this.order.getCharge(charge)?.amount ?? 0) / 100;
        },
        pretax(charge: models.ChargeType) {
            return (this.order.getCharge(charge)?.pretax() ?? 0) / 100;
        },
        localShippingState() {
            return (
                this.game.sale.seller.homeState.toLowerCase() ===
                this.order.effectiveShipping.state.toLowerCase()
            );
        },

        toggleShipping(event: Event) {
            this.order.shipping = (event.target as HTMLInputElement).checked
                ? this.shipping
                : null;

            //  TODO(ghochee): This can be cleaned up even further. Maybe a computed
            //  property or something.
            this.updateFreightCharge();
        },

        async updateFreightCharge() {
            if (!this.order.game.sale.addFreight) return;
            if (!this.order.effectiveShipping.deliveryPostcodeValid()) return;
            return this.order.updateFreightCharge(
                await this.freightCalculator.get(
                    this.order.effectiveShipping.deliveryPostcode,
                    this.order.game.sale.weight
                )
            );
        },

        async placeOrder() {
            event('begin_checkout', { value: this.total });
            const response = await api.post('/receipts', {
                // We send back the order total along with the order since the
                // order total is what we show to the end user. The backend will
                // reconstruct the order from the order field and validate all
                // details like actual shipping charge and coupons validity. At
                // the end the total then needs to match the total that we
                // showed the user. In that sense it is a checksum.
                amount: this.order.total(),
                order: this.order.serialize(),
            });

            const upiOnlyPaymentConfig = {
                display: {
                    hide: [
                        { method: 'app' },
                        { method: 'card' },
                        { method: 'cardless_emi' },
                        { method: 'emi' },
                        { method: 'netbanking' },
                        { method: 'paylater' },
                        { method: 'wallet' },
                    ],
                },
            };

            const nonUpiPaymentConfig = {
                display: { hide: [{ method: 'upi' }] },
            };

            const { orderId, receiptId, amountDue, currency } = response.data;

            // TODO(ghochee): The following Math.round is required because
            // 2397.8 * 100 === 239780 returns false (in chrome console test)
            //
            // The float multiplied by 100 has very small trailing values which
            // are not easily discarded. This works because we effectively
            // convert back to integer.
            if (
                amountDue !== Math.round(this.total * 100) ||
                currency !== 'INR'
            ) {
                this.$router.push(`Error`);
                return;
            }

            const self = this;

            // TODO(ghochee): Take user input to fill out these fields.
            const options = {
                // TODO(ghochee): Test that this variable is set during
                // initialization of the application / component.
                key: process.env.VUE_APP_RAZORPAY_USERNAME,
                amount: amountDue,
                currency: currency,
                name: 'Cryptic Cardboard LLP',
                description: this.order.game.name + ' purchase',
                image: 'https://ccbgames.in/static/images/logo-160w.jpg',
                order_id: orderId,
                handler: async function (success: any) {
                    await api.post(`/razorpay/${success.razorpay_order_id}`, {
                        paymentId: success.razorpay_payment_id,
                        signature: success.razorpay_signature,
                    });

                    event('purchase', {
                        transaction_id: orderId,
                        value: self.total,
                        tax: self.gst,
                        items: [
                            {
                                item_id: self.order.game.canonicalName,
                                item_name: self.game.name,
                            },
                        ],
                    });

                    self.$router.push(`/payment/${receiptId}`);
                },
                prefill: {
                    name: this.billing.name,
                    email: this.billing.email,
                    contact: this.billing.contact,
                },
                notes: {
                    // Section can hold 15 key-value pairs of 256 characters each
                    // https://razorpay.com/docs/payment-gateway/web-integration/standard/checkout-options/
                    'billing-name': this.billing.name,
                    'billing-email': this.billing.email,
                    'billing-contact': this.billing.contact,
                    'billing-address': this.billing.address,
                    'billing-city': this.billing.city,
                    'billing-state': this.billing.state,
                    'billing-deliveryPostcode': this.billing.deliveryPostcode,
                    'shipping-name': this.shipping.name,
                    'shipping-email': this.shipping.email,
                    'shipping-contact': this.shipping.contact,
                    'shipping-address': this.shipping.address,
                    'shipping-city': this.shipping.city,
                    'shipping-state': this.shipping.state,
                    'shipping-deliveryPostcode': this.shipping.deliveryPostcode,
                    receiptId,
                },
                theme: {
                    color: '#7EC7AC',
                },
                retry: {
                    enabled: true,
                    max_count: 1,
                },
                config:
                    this.order.upiPayment ||
                    this.order.game.canonicalName === 'trace-the-waste'
                        ? upiOnlyPaymentConfig
                        : nonUpiPaymentConfig,
            };
            // eslint-disable-next-line no-undef
            const razorPay = new Razorpay(options);
            razorPay.on('payment.failed', function () {
                self.paymentFailed = true;
            });
            this.paymentFailed = false;
            await razorPay.open();
        },
    },
});
